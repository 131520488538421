import React, {useState} from 'react';
import authenticate from './LoginAPI';

import './Login.css'
import UserMessage from '../Components/UserMessage/UserMessage';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Container, TextField } from '@mui/material';

const Login = ({setToken}) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState();
  const [open, setOpen] = useState(false);

  const theme = createTheme();

  const handleSubmit = async e => {
    e.preventDefault();
    showInprogressWarning();
    const response = await authenticate({username, password});
    if(response.error !== undefined) {
      setMessage(response.error)
      setMessageType(response.type)
    }
    else {
      if(response.data.token) {
        setMessage('');
        setToken(response.data);
      }
      else {
        setMessage('Sistema temporariamente indisponível! Tente novamente mais tarde!');
        setMessageType('error');
      }
    }

    setOpen(false);
  }

  const showInprogressWarning = aync => {
    setOpen(true);
    setMessage('Verificando...')
    setMessageType('warning')
  }

  return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" className="login-wrapper">
        <Box className='login-box'>
          <img alt='Logo' className='logo' src='icon-orig.png' />
          <span className='title'>
            Informe as credenciais
          </span>
          <UserMessage message={message} type={messageType} loading={open} />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="txtLogin" label="Usuário" name="userName" autoComplete="Usuário" autoFocus
              onChange={e => setUsername(e.target.value)}
            />
            <TextField margin="normal" required fullWidth name="password" label="Senha" type="password" id="txtPassword" autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
              Entrar
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Login;