import { baseAuthURL, doDELETE, doGET, doPOST, doPUT } from "../../ApiHelper"

const path = `${baseAuthURL}/menu`;
const pathRole = `${baseAuthURL}/role`;
const pathMenuRole = `${baseAuthURL}/menu-role`;

export const find = async(query, onlyActive, itemsPerPage, offset, {setToken}) => {
    let params = [];
    params.push(`limit=${itemsPerPage}`);
    params.push(`offset=${offset}`);

    if(onlyActive === true) {
        params.push(`isActive=${onlyActive}`)
    }

    if(query !== '') {
        params.push(`query=${query}`)
    }

    let findPath = `${path}?${params.join("&")}`
    return await doGET(findPath, {setToken});
}

export const findParents = async({setToken}) => {
    let findPath = `${path}/parents`
    return await doGET(findPath, {setToken});
}

export const inactivateById = async(id, {setToken}) => {
    const url = `${path}/${id}`;
    return doDELETE(url, {setToken});
}

export const loadDetail = async(id, {setToken}) => {
    return doGET(`${path}/id/${id}`, {setToken});
}

export const save = (id, name, isActive, menuPath, icon, parentId, {setToken}) => {
    let data = {
        "name": name,
        "isActive": isActive,
        "icon": icon
    }

    if(menuPath !== undefined && menuPath !== '') {
        data['path'] = menuPath;
    }

    if(parentId !== undefined && parentId !== '' && parentId !== 'none') {
        data['parent'] = parentId;
    }

    if(id === undefined) {
        return doPOST(path, data, {setToken});
    }
    else {
        return doPUT(`${path}/${id}`, data, {setToken});
    }
}

export const available = async({setToken}) => {
    let findPath = `${path}/available`
    return await doGET(findPath, {setToken});
}

export const findActiveRoles = ({setToken}) => {
    return doGET(`${pathRole}?isActive=true&withPagination=false`, {setToken});
}

export const findMenuRoles = (id, {setToken}) => {
    return doGET(`${pathMenuRole}/menu/${id}`, {setToken});
}

export const saveMenuRoles = (menuId, roles, {setToken}) => {
    let data = {
        "menuId": menuId,
        "roles": roles
    }

    return doPUT(`${pathMenuRole}/update-roles`, JSON.stringify(data), {setToken});
}