import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserMessage from '../../Components/UserMessage/UserMessage';
import { useParams, useNavigate } from 'react-router-dom';
import useToken from '../../App/useToken';
import { Box } from '@mui/system';
import { Button, FormControl, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import { loadDetail, save } from './RoleApi';

const RoleDetail = () => {
    const theme = createTheme();
    const {token, setToken} = useToken();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [detail, setDetail] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        loadPage();
    });

    const loadPage = () => {
        if(pageLoaded === false) {
            setPageLoaded(true);
            if(id !== undefined) {
                setLoading(true);
                loadDetail(id, {setToken}).then((res) => {
                    setDetail(res.data);
                    setName(res.data.name);
                    setIsActive(res.data.isActive);
                    setLoading(false);
                });
            }
        }
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangeIsActive = (event) => {
        setIsActive(event.target.checked);
    }

    const handleBackClick = () => {
        navigate(`/role`);
    }

    const handleSaveClick = () => {
        setMessage('Salvando...');
        setMessageType('warning');
        setLoading(true);

        save(id, name, isActive, {setToken}).then((res) => {
            navigate(`/role`);
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className='contentHolder' role='presentation'>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography component="h1" variant="h5" className='pageTitle'>
                        {id === undefined ? 'Novo Perfil' : 'Detalhes do Perfil'}
                    </Typography>
                    <UserMessage message={message} type={messageType} loading={loading} />
                    <TextField fullWidth className='formInput' onChange={onChangeName} value={name} label="Nome"  variant="standard" />
                    <FormControlLabel control={<Switch onClick={onChangeIsActive} checked={isActive} inputProps={{ 'aria-label': 'controlled' }} />} label="Ativo?" />
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.company === undefined || detail.company.name === undefined ? '' : detail.company.name}
                                label="Empresa" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' value={detail.createdBy === undefined ? '' : detail.createdBy}
                                label="Criado por" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' value={detail.lastUpdatedBy === undefined ? '' : detail.lastUpdatedBy}
                                label="Atualizado por" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.createdAt === undefined ? '' : new Date(detail.createdAt).toLocaleString('pt-BR')}
                                label="Criado em" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.lastUpdatedAt === undefined ? '' : new Date(detail.lastUpdatedAt).toLocaleString('pt-BR')}
                                label="Atualizado em" disabled variant="standard" />
                        </FormControl>
                    }
                    <div className="buttonGroup">
                        <Button onClick={handleBackClick} variant='contained'>Voltar</Button>
                        <Button onClick={handleSaveClick} variant='contained' color='success'>Salvar</Button>
                    </div>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default RoleDetail;