import React, {useState, useEffect} from 'react';
import UserMessage from '../Components/UserMessage/UserMessage';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Home = () => {

    const theme = createTheme();
    const [message, setMessage] = useState();
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        setMessage('');
    }, []);

    return(
        <ThemeProvider theme={theme}>
            <UserMessage message={message} type={messageType} loading={loading} />
        </ThemeProvider>
    );
}

export default Home;