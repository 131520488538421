import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserMessage from '../../Components/UserMessage/UserMessage';
import { useParams, useNavigate } from 'react-router-dom';
import useToken from '../../App/useToken';
import { Box } from '@mui/system';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography, Link, Dialog, DialogTitle, DialogActions, DialogContent, FormGroup, Checkbox } from '@mui/material';
import { loadDetail, save, findParents, findActiveRoles, findMenuRoles, saveMenuRoles } from './MenuApi';


const MenuDetail = () => {
    const theme = createTheme();
    const {token, setToken} = useToken();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [name, setName] = useState('');
    const [path, setPath] = useState('');
    const [icon, setIcon] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [parentId, setParentId] = useState('none');
    const [parentMenuList, setParentMenuList] = useState([{id: 'none', name: 'Selecione...'}]);
    const [detail, setDetail] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
    const [menuRoles, setMenuRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        loadPage();
    });

    const loadPage = async() => {
        if(pageLoaded === false) {
            setPageLoaded(true);
            await loadParents();
            if(id !== undefined) {
                setLoading(true);
                loadDetail(id, {setToken}).then((res) => {
                    setDetail(res.data);
                    setName(res.data.name);
                    setPath(res.data.path === undefined ? '' : res.data.path);
                    setIcon(res.data.icon);
                    setIsActive(res.data.isActive);
                    
                    if(res.data.parent !== undefined) {
                        setParentId(res.data.parent.id);
                    }
                    
                    setLoading(false);
                });
            }
        }
    }

    const loadParents = async() => {
        setLoading(true);
        let res = await findParents({setToken});
        if(res.error === undefined) {
            let list = [];
            list.push({id: 'none', name: 'Selecione...'})
            res.data.map((item) => {
                list.push(item);
                return item;
            })
            
            setParentMenuList(list);
        }
        else {
            setMessage(res.error);
            setMessageType('error');
        }
        setLoading(false);
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangePath = (event) => {
        setPath(event.target.value);
    }

    const onChangeIcon = (event) => {
        setIcon(event.target.value);
    }

    const onChangeIsActive = (event) => {
        setIsActive(event.target.checked);
    }

    const handleBackClick = () => {
        navigate(`/menu`);
    }

    const handleSaveClick = () => {
        setMessage('Salvando...');
        setMessageType('warning');
        setLoading(true);

        save(id, name, isActive, path, icon, parentId, {setToken}).then((res) => {
            if(res.error === undefined) {
                setMessage('Menu salvo com sucesso!');
                setMessageType('success');
                navigate(`/menu`);
            }
            else {
                setMessage('Erro ao salvar menu! ' + res.error);
                setMessageType('error');
                setLoading(false);
            }
        });
    }

    const handleParentChange = (event) => {
        setParentId(event.target.value);
    }

    const loadActiveRoles = async() => {
        if(roleList.length === 0) {
            let res = await findActiveRoles({setToken})
            if(res.error === undefined) {
                let activeRoles = [];
                res.data.list.map((selectedRole) => {
                    activeRoles.push(selectedRole);
                    return activeRoles;
                });
                setRoleList(activeRoles);
            }
        }
    }

    const loadMenuRoles = async() => {
        if(selectedRoles.length === 0) {
            let res = await findMenuRoles(id, {setToken});
            if(res.error === undefined) {
                setMenuRoles(res.data);
                let menuSelectedRoles = [];
                res.data.map((selectedRole) => {
                    menuSelectedRoles.push(selectedRole.role.id);
                    return menuSelectedRoles;
                });
                setSelectedRoles(menuSelectedRoles);
            }
        }
    }

    const handleOpenRolesDialog = () => {
        setIsRolesDialogOpen(true);
        loadMenuRoles();
        loadActiveRoles();
    }

    const handleRolesDialogCancel = () => {
        setIsRolesDialogOpen(false);
    }

    const handleRolesDialogSave = async() => {
        let res = saveMenuRoles(id, selectedRoles, {setToken})
        setMessage('Atualizando perfis com acesso ao menu...');
        setMessageType('warning');
        if(res.error === undefined) {
            setMessage('Perfis com acesso ao menu atualizados com sucesso!');
            setMessageType('success');
        }
        else {
            setMessage('Erro ao atualizar com acesso ao menu! Tente novamente');
            setMessageType('error');
            setSelectedRoles([...menuRoles]);
        }
        setIsRolesDialogOpen(false);
    }

    const handleRoleClick = (event) => {
        if(event.target.checked === true) {
            setSelectedRoles([...selectedRoles, event.target.value]);
        }
        else {
            setSelectedRoles(selectedRoles.filter(role => role !== event.target.value));
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className='contentHolder' role='presentation'>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography component="h1" variant="h5" className='pageTitle'>
                        {id === undefined ? 'Novo Menu' : 'Detalhes do Menu'}
                    </Typography>
                    <UserMessage message={message} type={messageType} loading={loading} />
                    <TextField fullWidth className='formInput' onChange={onChangeName} value={name} label="Nome"  variant="standard" />
                    <TextField fullWidth className='formInput' onChange={onChangePath} value={path} label="Caminho"  variant="standard" />
                    <FormControl  fullWidth sx={{mt: '16px'}}>
                        <InputLabel id="parentLabel">Menu Pai</InputLabel>
                        <Select labelId='parentLabel' value={parentId} label="Menu Pai" onChange={handleParentChange} variant='standard'>
                            {
                                parentMenuList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <TextField fullWidth className='formInput' onChange={onChangeIcon} value={icon} label="Ícone"  variant="standard" />
                    <FormControlLabel control={<Switch onClick={onChangeIsActive} checked={isActive} inputProps={{ 'aria-label': 'controlled' }} />} label="Ativo?" />
                    {id &&
                        <Link component='button' onClick={handleOpenRolesDialog}>
                            Perfis
                        </Link>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' value={detail.createdBy === undefined ? '' : detail.createdBy}
                                label="Criado por" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' value={detail.lastUpdatedBy === undefined ? '' : detail.lastUpdatedBy}
                                label="Atualizado por" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.createdAt === undefined ? '' : new Date(detail.createdAt).toLocaleString('pt-BR')}
                                label="Criado em" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.lastUpdatedAt === undefined ? '' : new Date(detail.lastUpdatedAt).toLocaleString('pt-BR')}
                                label="Atualizado em" disabled variant="standard" />
                        </FormControl>
                    }
                    <div className="buttonGroup">
                        <Button onClick={handleBackClick} variant='contained'>Voltar</Button>
                        <Button onClick={handleSaveClick} variant='contained' color='success'>Salvar</Button>
                    </div>
                </Grid>
            </Box>
            <Dialog open={isRolesDialogOpen} scroll='paper' className='rolesDialog'>
                <DialogTitle>Perfis</DialogTitle>
                <DialogContent dividers>
                    <FormGroup>
                        {roleList === undefined || roleList.length === 0 &&
                            "Carregando..."
                        }
                        {roleList &&
                            roleList.map((role) => (
                                <FormControlLabel key={`lbl${role.id}`}
                                    control={<Checkbox key={role.id} checked={selectedRoles.includes(role.id)} onClick={handleRoleClick} value={role.id} />}
                                    label={role.name} />
                            ))
                        }
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleRolesDialogCancel}>Cancelar</Button>
                    <Button onClick={handleRolesDialogSave}>Salvar</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default MenuDetail;