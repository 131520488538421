import axios from 'axios';

export const baseAuthURL = process.env.REACT_APP_AUTH_BASE_URL;
export const baseAdminURL = process.env.REACT_APP_ADMIN_BASE_URL;
export const baseWorkflowURL = process.env.REACT_APP_WORKFLOW_BASE_URL;

export const httpHeaders = {
    'Content-Type': 'application/json',
};

const handleRequestError = (error, {setToken}) => {
    console.log(`error: ${error}`);
    if(error?.response !== undefined && (error?.response?.status === 403 || error?.response?.status === 400)) {
        const response = error.response.data
        if(response.code === 'INVALID_TOKEN') {
            setToken(null);
            return {error: 'Acesso inválido, faça login novamente!', type: 'error', code: 'INVALID_ACCESS'}
        }

        return {error: 'Você não possui acesso a essa área, procure o Administrador!', type: 'error', code: 'INVALID_ACCESS'}
    }
    else if(error !== undefined) {
        return {error: error, type: 'error'}
    }
    else {
        return {error: 'Sistema temporariamente indisponível, tente mais tarde!', type: 'error'}
    }
}

const buildConfigRequest = (headers, options) => {
    let token = JSON.parse(localStorage.getItem('userToken'));
    let httpConfigRequest = {
        headers: {
            ...httpHeaders,
            'Authorization': token.token
        },
        ...options
    }

    headers.map(header => (
        httpConfigRequest.headers = {
            ...httpConfigRequest.headers,
            ...header
        }
    ));

    return httpConfigRequest;
}

export const doGET = async(url, {setToken}, headers = [], options = {}) => {
    const httpConfigRequest = buildConfigRequest(headers, options);
    let result = undefined;
    try {
        result = await axios.get(url, httpConfigRequest);
    }
    catch(error) {
        return handleRequestError(error, {setToken});
    }

    return result;
}

export const doPUT = async(url, data, {setToken}, headers = [], options = {}) => {
    const httpConfigRequest = buildConfigRequest(headers, options);  
    let result = undefined;
    try {
        result = await axios.put(url, data, httpConfigRequest);
    }
    catch(error) {
        return handleRequestError(error, {setToken});
    }

    return result;
}

export const doDELETE = async(url, {setToken}, headers = [], options = {}) => {
    const httpConfigRequest = buildConfigRequest(headers, options);  
    let result = undefined;
    try {
        result = await axios.delete(url, httpConfigRequest);
    }
    catch(error) {
        return handleRequestError(error, {setToken});
    }
  
    return result;
}

export const doPOST = async(url, data, {setToken}, headers = [], options = {}) => {
    const httpConfigRequest = buildConfigRequest(headers, options);  
    let result = undefined;
    try {
        result = await axios.post(url, data, httpConfigRequest);
    }
    catch(error) {
        return handleRequestError(error, {setToken});
    }
  
    return result;
}