import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserMessage from '../../Components/UserMessage/UserMessage';
import { Box } from '@mui/system';
import { Button, FormControl, Input, InputAdornment, InputLabel, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TableCrud from '../../Components/TableCrud/TableCrud';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useToken from '../../App/useToken';
import { useNavigate } from 'react-router-dom';
import { inactivateById, find } from "./RoleApi";

const Role = () => {
    const theme = createTheme();
    const {token, setToken} = useToken();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(1);
    const [query, setQuery] = useState('');
    const [onlyActive, setOnlyActive] = useState(true);
    const [pageLoad, setPageLoad] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        loadRows();
    })

    const columns = [
        {label: 'Nome', id: 'name'},
        {label: 'Ativo?', id: 'isActive', type: 'boolean'},
        {label: 'Company', id: 'company.name'},
        {label: 'Ações', id: 'actions', type: 'action'}
    ]

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        setPage(newPage);
        let offset = newPage * rowsPerPage;
        doSearch(rowsPerPage, offset)
    };

    const handleChangeRowsPerPage = (event) => {
        setLoading(true);
        setPage(0);
        setRowsPerPage(event.target.value);
        doSearch(event.target.value, 0);
    };

    const handleEdit = (id) => {
        setLoading(true);
        navigate(`/role/${id}`);
    };

    const handleDelete = (id) => {
        setLoading(true);
        setMessage('Inativando Perfil');
        setMessageType('warning');
        inactivateById(id, {setToken}).then(res => {
            if(res.error === undefined) {
                doSearch();
            }
            else {
                setMessage(res.error);
                setMessageType(res.type);
                setLoading(false);
            }
        });
    };

    const handleSearchClick = () => {
        setLoading(true);
        setPage(0);
        doSearch(rowsPerPage, 0);
    }

    const loadRows = () => {
        if(pageLoad === false) {
            setPageLoad(true);
            setLoading(true);
            setMessage('Carregando lista de perfis...');
            setMessageType('warning');
            doSearch();
        }
    };

    const doSearch = (requestedRows = rowsPerPage, offset = 0) => {
        setLoading(true);
        setMessage('Carregando perfis');
        setMessageType('warning');
        find(query, onlyActive, requestedRows, offset, {setToken}).then(res => {
            if(res.error === undefined) {
                let retrivedRows = res.data.list;
                setTotalRows(res.data.total);
                setRows(retrivedRows);
                setMessage('');
            }
            else {
                setMessage(res.error);
                setMessageType('error');
            }
            
            setLoading(false);
        });
    };

    const onInputQuery = (event) => {
        if(event.key === 'Enter') {
            doSearch();
        }
        
        setQuery(event.target.value);
    }

    const newRole = () => {
        navigate(`/role/add`);
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className='contentHolder' role='presentation' sx={{mt: 0}}>
                <Typography component="h1" variant="h5" className='pageTitle'>
                    Perfil
                </Typography>
                <UserMessage message={message} type={messageType} loading={loading} />
                <Box className='topControls' role='presentation'>
                    <FormControl variant="standard">
                        <InputLabel htmlFor="input-with-icon-adornment">
                            Busca
                        </InputLabel>
                        <Input id="input-with-icon-adornment" className='searchInput'
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            onKeyUp={e => onInputQuery(e)}
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{paddingLeft: '20px'}}>
                        <FormControlLabel control={<Switch defaultChecked onClick={e => setOnlyActive(e.target.checked)} />} label="Somente Ativos?" />
                    </FormControl>
                    <Button key='btnSearch' className='searchButton' variant="contained" onClick={handleSearchClick}>Buscar</Button>
                    <Button key='btnNew' className='newButton' variant="contained" onClick={newRole} color="success" sx={{ml: '10px'}}>Novo</Button>
                </Box>
                <TableCrud 
                    columns={columns}
                    rows={rows}
                    page={page}
                    pageCount={totalRows}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                />
            </Box>
        </ThemeProvider>
    )
}

export default Role;