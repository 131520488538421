import { baseAuthURL, doDELETE, doGET, doPOST, doPUT } from "../../ApiHelper"

const path = `${baseAuthURL}/authorization`;
const pathRoleAuthorization = `${baseAuthURL}/role-authorization`;
const rolePath = `${baseAuthURL}/role`;

export const find = async(query, onlyActive, itemsPerPage, offset, {setToken}) => {
    let params = [];
    params.push(`limit=${itemsPerPage}`);
    params.push(`offset=${offset}`);

    if(query !== '') {
        params.push(`query=${query}`)
    }

    if(onlyActive === true) {
        params.push(`isActive=${onlyActive}`)
    }

    let findPath = `${path}?${params.join("&")}`
    return await doGET(findPath, {setToken});
}

export const findActiveRoles = ({setToken}) => {
    return doGET(`${rolePath}?isActive=true&withPagination=false`, {setToken})
}

export const findAuthorizationRoles = (id, {setToken}) => {
    return doGET(`${pathRoleAuthorization}?authorization=${id}`, {setToken});
}

export const findTypes = ({setToken}) => {
    return doGET(`${path}/type`, {setToken})
}

export const findActions = ({setToken}) => {
    return doGET(`${path}/action`, {setToken})
}

export const deleteById = async(id, {setToken}) => {
    const url = `${path}/id/${id}`;
    return doDELETE(url, {setToken});
}

export const loadDetail = async(id, {setToken}) => {
    return doGET(`${path}/id/${id}`, {setToken});
}

export const save = (id, role, authorizedPath, type, action, {setToken}) => {
    let data = {
        "role": role.id,
        "path": authorizedPath,
        "type": type,
        "action": action
    }

    if(id === undefined) {
        return doPOST(path, data, {setToken});
    }
    else {
        return doPUT(`${path}/id/${id}`, JSON.stringify(data), {setToken});
    }
}


export const saveAuthorizationRoles = (authorizationId, roles, {setToken}) => {
    let data = {
        "roles": roles
    }

    return doPOST(`${pathRoleAuthorization}/authorization/${authorizationId}`, JSON.stringify(data), {setToken});
}