import React from 'react';
import { Box, Chip, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const TableCrud = ({columns, rows, page, pageCount, rowsPerPage, handleChangePage, handleChangeRowsPerPage, handleEdit, handleDelete}) => {

    const format = (row, attributeId, type, id) => {
        let data = extractValue(row, attributeId);
        if(type === 'boolean') {
            const description = data === true ? 'Sim' : 'Não';
            const color = data === true ? 'success' : 'error';
            return <Chip label={description} size="large" color={color} />;
        }
        else if(type === 'datetime') {
            if(data === undefined || data === '')
                return '';

            const datetime = new Date(data);
            return datetime.toLocaleString('pt-br');
        }
        else if(type === 'phone') {
            let formatted = (data.length === 11) ?
                `(${data.substr(0, 2)}) ${data.substr(2, 5)}-${data.substr(7)}`:
                `(${data.substr(0, 2)}) ${data.substr(2, 4)}-${data.substr(6)}`;
            
            return formatted;
        }
        else if(type === 'icon') {
            return (
                <Icon>{data}</Icon>
            )
        }
        else if(type === 'action') {
            return (
                    <Box role='presentation' sx={{minWidth: '80px', maxWidth: '100px'}}>
                        <IconButton aria-label="delete" color="error" onClick={() => {handleDelete(id)}}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton aria-label="edit" color="primary" onClick={() => {handleEdit(id)}}>
                            <EditIcon />
                        </IconButton>
                    </Box>
            )
        }

        return data;
    }

    const extractValue = (row, attributeId) => {
        if(attributeId.includes('.') === true) {
            let keyParts = attributeId.split('.')
            let result = row;
            keyParts.map((key) => (
                result = result[key]
            ))

            return result;
        }

        return row[attributeId];
    }

    return (
        <>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead className='tableCrudHeader'>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            return (
                                                <TableCell key={column.id}>
                                                    {format(row, column.id, column.type, row.id)}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

export default TableCrud;