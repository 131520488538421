import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NoMatchRoute = () => {
    let navigate = useNavigate();
    
    useEffect(() => {
        navigate('/home');
    })

    return (
        <div>
            Página não encontrada
        </div>
    )
}

export default NoMatchRoute;