import { baseAuthURL, doDELETE, doGET, doPOST, doPUT } from "../../ApiHelper"

const path = `${baseAuthURL}/role`;

export const find = async(query, onlyActive, itemsPerPage, offset, {setToken}) => {
    let params = [];
    params.push(`limit=${itemsPerPage}`);
    params.push(`offset=${offset}`);

    if(onlyActive === true) {
        params.push(`isActive=${onlyActive}`)
    }

    if(query !== '') {
        params.push(`query=${query}`)
    }

    let findPath = `${path}?${params.join("&")}`
    return await doGET(findPath, {setToken});
}

export const inactivateById = async(id, {setToken}) => {
    const url = `${path}/${id}`;
    return doDELETE(url, {setToken});
}

export const loadDetail = async(id, {setToken}) => {
    return doGET(`${path}/${id}`, {setToken});
}

export const save = (id, name, isActive, {setToken}) => {
    let data = {
        "name": name,
        "isActive": isActive
    }

    if(id === undefined) {
        return doPOST(path, data, {setToken});
    }
    else {
        return doPUT(`${path}/${id}`, JSON.stringify(data), {setToken});
    }
}