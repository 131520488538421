import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('userToken');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    if(userToken === null) {
      localStorage.removeItem('userToken');
      setToken(null);
    }
    else {
      localStorage.setItem('userToken', JSON.stringify(userToken));
      setToken(userToken)
    }
  };

  return {
    setToken: saveToken,
    token
  }
}