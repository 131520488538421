import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import NoMatchRoute from './NoMatchRoute/NoMatchRoute';
import Divider from '@mui/material/Divider';
import useToken from './App/useToken';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Button, Collapse, Icon, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import Login from './Login/Login';
import Home from './Home/Home';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router-dom";
import User from "./Auth/User/User";
import Role from "./Auth/Role/Role";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './App.css';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import UserDetail from "./Auth/User/UserDetail";
import Company from "./Auth/Company/Company";
import CompanyDetail from "./Auth/Company/CompanyDetail";
import RoleDetail from "./Auth/Role/RoleDetail";
import Authorization from "./Auth/Authorization/Authorization";
import AuthorizationDetail from "./Auth/Authorization/AuthorizationDetail";
import MenuCRUD from './Auth/Menu/Menu';
import MenuDetail from "./Auth/Menu/MenuDetail";
import { available } from './Auth/Menu/MenuApi';

const App = () => {
  let navigate = useNavigate();
  const drawerAnchor = 'left';
  const theme = createTheme();
  const { token, setToken } = useToken();
  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const [ menuItems, setMenuItems ] = useState([]);
  const [ menuItemState, setMenuItemState ] = useState([]);
  const [ isMenuLoaded, setIsMenuLoaded ] = useState(false);
  const [ isMenuLoadedSuccess, setIsMenuLoadedSuccess ] = useState(false);
  const [ anchorEl, setAnchorEl] = useState();

  const loadMenuItems = async(force = false) => {
    if(isMenuLoaded === false || force === true) {
      let res = await available({setToken});
      setIsMenuLoaded(true);
      if(res.error === undefined) {
        setMenuItems(res.data);
        setIsMenuLoadedSuccess(true);
      }
      else {
        setIsMenuLoadedSuccess(false);
      }
    }
  }

  const logout = () => {
    setIsMenuOpen(false);
    localStorage.removeItem('userToken')
    setToken('')
    setIsMenuLoaded(false);
    setIsMenuLoadedSuccess(false);
  }

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    
    if(isMenuLoaded === false) {
      loadMenuItems();
    }
  }

  const toggleMenuItem = (index) => {
    setMenuItemState((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  }

  const menuNavigate = (url) => {
    if(typeof url === 'string') {
      navigate(url, {replace: true})
      setIsDrawerOpen(false);
    }
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const openMenu = (e) => {
    setAnchorEl(e.target);
    setIsMenuOpen(true);
  }

  const reloadMenuDrawer = () => {
    setIsMenuLoaded(false);
    loadMenuItems(true);
  }

  const aboutMeClick = () => {
    console.log("Implmentar sobre mim...");
  }

  if(!token || new Date().getTime() > Date.parse(token.expiresAt)) {
    return <Login setToken={setToken} />
  }

  return (
    <ThemeProvider theme={theme}>
      <header className="siteHeader">
        <AppBar className="appBarTitle">
          <Toolbar className="toolBar">
            <IconButton color="inherit" aria-label="Menu" onClick={toggleDrawer} edge="start">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="h1">Flowmanager - Portal</Typography>
            <div className="divExit">
              <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={() => {setIsMenuOpen(false)}}>
                <MenuItem onClick={aboutMeClick}>Sobre mim</MenuItem>
                <MenuItem onClick={logout}>Sair</MenuItem>
              </Menu>
              <Button {...{
                color: "inherit",
                component: RouterLink,
                onClick: openMenu
              }}>
                <AccountCircleIcon />
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <React.Fragment key={drawerAnchor}>
          <Button onClick={toggleDrawer}>{drawerAnchor}</Button>
          <Drawer open={isDrawerOpen} anchor={drawerAnchor} variant="persistent">
            <DrawerHeader className="menuHeader">
              Menu
              <IconButton onClick={toggleDrawer} className="closeMenuIcon" sx={{mx: 'auto', mr: 0}}>
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {isMenuLoaded === false && 
                "Carregando..."
              }
              {isMenuLoaded === true && isMenuLoadedSuccess === false &&
                <>
                  Erro ao carregar menu, clique
                  <Link component='button' onClick={reloadMenuDrawer}>&nbsp;aqui&nbsp;</Link>
                  para tentar novamente
                </>
              }
              {isMenuLoaded === true && isMenuLoadedSuccess === true && menuItems.length === 0 &&
                <>
                  Menu carregado com sucesso, mas vazio, clique
                  <Link component='button' onClick={reloadMenuDrawer}>&nbsp;aqui&nbsp;</Link>
                  para tentar novamente
                </>
              }
              {menuItems.length > 0 && 
                menuItems.map((menuItem, index) => (
                  menuItem.children === undefined ? 
                    <ListItemButton key={index} onClick={() => {menuNavigate(menuItem.url)}}>
                      <ListItemIcon>
                        <Icon>{menuItem.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={menuItem.text} />
                    </ListItemButton>
                    :
                    <React.Fragment key={index}>
                      <ListItemButton onClick={() => {toggleMenuItem(index)}}>
                        <ListItemIcon>
                          <Icon>{menuItem.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={menuItem.text} />
                        {menuItemState[index] === true ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse key={'collapse_'+index} in={menuItemState[index]} unmountOnExit>
                        <List key={'list_'+index} component="div" sx={{ml: '30px'}} disablePadding>
                          {
                            menuItem.children.map((child, childIndex) => (
                              <ListItemButton key={index + ':' + childIndex} onClick={() => {menuNavigate(child.url)}}>
                                <ListItemIcon>
                                  <Icon>{child.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={child.text} />
                              </ListItemButton>
                            ))
                          }
                        </List>
                      </Collapse>
                    </React.Fragment>
                ))
              }
            </List>
          </Drawer>
        </React.Fragment>
      </header>
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="user" element={<User />} />
        <Route path="user/add" element={<UserDetail />} />
        <Route path="user/:id" element={<UserDetail />} />
        <Route path="company" element={<Company />} />
        <Route path="company/add" element={<CompanyDetail />} />
        <Route path="company/:id" element={<CompanyDetail />} />
        <Route path="role" element={<Role />} />
        <Route path="role/add" element={<RoleDetail />} />
        <Route path="role/:id" element={<RoleDetail />} />
        <Route path="authorization" element={<Authorization />} />
        <Route path="authorization/add" element={<AuthorizationDetail />} />
        <Route path="authorization/:id" element={<AuthorizationDetail />} />
        <Route path="menu" element={<MenuCRUD />} />
        <Route path="menu/add" element={<MenuDetail />} />
        <Route path="menu/:id" element={<MenuDetail />} />
        <Route path="*" element={<NoMatchRoute />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;