import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserMessage from '../../Components/UserMessage/UserMessage';
import { useParams, useNavigate } from 'react-router-dom';
import useToken from '../../App/useToken';
import { Box } from '@mui/system';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { findActions, findActiveRoles, findAuthorizationRoles, findTypes, loadDetail, save, saveAuthorizationRoles } from './AuthorizationApi';
import { indigo } from '@mui/material/colors';
import { Group } from '@mui/icons-material';
import { light } from '@mui/material/styles/createPalette';

const AuthorizationDetail = () => {
    const theme = createTheme({
        palette: {
            manage: {
                main: '#3f51b5',
                contrastText: '#fff',
                light: '#7986cb'
            }
        }
    });
    const {token, setToken} = useToken();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [role, setRole] = useState({'id': 'none'});
    const [path, setPath] = useState('');
    const [type, setType] = useState('none');
    const [action, setAction] = useState('none');
    const [detail, setDetail] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [typeList, setTypeList] = useState([{id: 'none', name: 'Selecione...'}]);
    const [actionList, setActionList] = useState([{id: 'none', name: 'Selecione...'}]);
    const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [authorizationRoles, setAuthorizationRoles] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const colorIndigo = indigo[500];

    let navigate = useNavigate();

    useEffect(() => {
        loadPage();
    });

    const loadPage = async() => {
        if(pageLoaded === false) {
            setPageLoaded(true);
            setMessage('Carregando detalhes...');
            setMessageType('warning');
            setLoading(true);
            await loadTypeList();
            await loadActionList();

            if(id !== undefined) {
                loadDetail(id, {setToken}).then((res) => {
                    setDetail(res.data);
                    setPath(res.data.path);
                    setType(res.data.type);
                    setAction(res.data.action);
                    setLoading(false);
                    setMessage('');
                });
            }
            else {
                setLoading(false);
                setMessage('');
            }
        }
    }

    const loadTypeList = async() => {
        let res = await findTypes({setToken});
        if(res.error === undefined) {
            let list = [];
            list.push({id: 'none', description: 'Selecione...'})
            res.data.list.map((item) => {
                list.push(item);
                return item;
            })

            setTypeList(list);
        }
    }

    const loadActionList = async() => {
        let res = await findActions({setToken});
        if(res.error === undefined) {
            let list = [];
            list.push({id: 'none', description: 'Selecione...'})
            res.data.list.map((item) => {
                list.push(item);
                return item;
            })

            setActionList(list);
        }
    }

    const handleBackClick = () => {
        navigate(`/authorization`);
    }

    const handleSaveClick = () => {
        setMessage('Salvando...');
        setMessageType('warning');
        setLoading(true);

        save(id, role, path, type, action, {setToken}).then((res) => {
            if(res.error === undefined) {
                navigate(`/authorization`);
            }
            else {
                setMessageType('error');
                setMessage(res.error.message);
                setLoading(false);
            }
        })
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
    }

    const handleActionChange = (event) => {
        setAction(event.target.value);
    }

    const handleOpenRolesDialog = () => {
        setIsRolesDialogOpen(true);
        loadAuthorizationRoles();
        loadActiveRoles();
    }

    const loadAuthorizationRoles = async() => {
        if(selectedRoles.length === 0) {
            let res = await findAuthorizationRoles(id, {setToken});
            if(res.error === undefined) {
                setAuthorizationRoles(res.data);
                let authorizationSelectedRoles = [];
                res.data.list.map((selectedRole) => {
                    authorizationSelectedRoles.push(selectedRole.role.id);
                    return authorizationSelectedRoles;
                });
                setSelectedRoles(authorizationSelectedRoles);
            }
        }
    }

    const loadActiveRoles = async() => {
        if(roleList.length === 0) {
            let res = await findActiveRoles({setToken})
            if(res.error === undefined) {
                let activeRoles = [];
                res.data.list.map((selectedRole) => {
                    activeRoles.push(selectedRole);
                    return activeRoles;
                });
                setRoleList(activeRoles);
            }
        }
    }

    const handleRoleClick = (event) => {
        if(event.target.checked === true) {
            setSelectedRoles([...selectedRoles, event.target.value]);
        }
        else {
            setSelectedRoles(selectedRoles.filter(role => role !== event.target.value));
        }
    }

    const handleRolesDialogCancel = () => {
        setIsRolesDialogOpen(false);
    }

    const handleRolesDialogSave = async() => {
        let res = await saveAuthorizationRoles(id, selectedRoles, {setToken})
        setMessage('Atualizando perfis com acesso ao recurso...');
        setMessageType('warning');
        if(res.error === undefined) {
            setMessage('Perfis com acesso ao recurso atualizados com sucesso!');
            setMessageType('success');
        }
        else {
            setMessage('Erro ao atualizar perfis com acesso ao recurso! Tente novamente');
            setMessageType('error');
            setSelectedRoles([...selectedRoles]);
        }
        setIsRolesDialogOpen(false);
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className='contentHolder' role='presentation'>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography component="h1" variant="h5" className='pageTitle'>
                        {id === undefined ? 'Nova Autorização' : 'Detalhes da Autorização'}
                    </Typography>
                    <UserMessage message={message} type={messageType} loading={loading} />
                    {id &&
                        <Fab variant="extended" onClick={handleOpenRolesDialog} color='manage' aria-label='Gerenciar Perfis' sx={{ml: 'auto', mr: '0px'}}>
                            <Group sx={{ mr: 1 }} />
                            Gerenciar Perfis
                        </Fab>
                    }
                    <TextField fullWidth className='formInput' onChange={(e) => setPath(e.target.value)} value={path} label="Caminho"  variant="standard" />
                    <FormControl fullWidth sx={{mt: '10px'}} variant="standard">
                        <InputLabel id="roleLabel">Tipo de Autorização</InputLabel>
                        <Select labelId='roleLabel' value={type} label="Tipo de Autorização" onChange={handleTypeChange} variant='standard'>
                            {
                                typeList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mt: '10px'}} variant="standard">
                        <InputLabel id="roleLabel">Tipo de Ação</InputLabel>
                        <Select labelId='roleLabel' value={action} label="Perfil" onChange={handleActionChange} variant='standard'>
                            {
                                actionList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.company === undefined ? '' : detail.company.name}
                                label="Empresa" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth
                                className='formInput'
                                value={detail.createdBy === undefined ? '' : detail.createdBy}
                                label="Criado por"
                                disabled
                                variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth
                                className='formInput'
                                value={detail.lastUpdatedBy === undefined ? '' : detail.lastUpdatedBy}
                                label="Atualizado por"
                                disabled
                                variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth
                                className='formInput' 
                                value={detail.createdAt === undefined ? '' : new Date(detail.createdAt).toLocaleString('pt-BR')}
                                label="Criado em"
                                disabled
                                variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth
                                className='formInput' 
                                value={detail.lastUpdatedAt === undefined ? '' : new Date(detail.lastUpdatedAt).toLocaleString('pt-BR')}
                                label="Atualizado em"
                                disabled
                                variant="standard" />
                        </FormControl>
                    }
                    <Dialog open={isRolesDialogOpen} scroll='paper' className='rolesDialog'>
                        <DialogTitle>Perfis</DialogTitle>
                        <DialogContent dividers>
                            <FormGroup>
                                {(roleList === undefined || roleList.length === 0) &&
                                    "Carregando..."
                                }
                                {roleList &&
                                    roleList.map((role) => (
                                        <FormControlLabel key={`lbl${role.id}`}
                                            control={<Checkbox key={role.id} checked={selectedRoles.includes(role.id)} onClick={handleRoleClick} value={role.id} />}
                                            label={role.name} />
                                    ))
                                }
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleRolesDialogCancel}>Cancelar</Button>
                            <Button onClick={handleRolesDialogSave}>Salvar</Button>
                        </DialogActions>
                    </Dialog>
                    <div className="buttonGroup">
                        <Button onClick={handleBackClick} variant='contained'>Voltar</Button>
                        <Button onClick={handleSaveClick} variant='contained' color='success'>Salvar</Button>
                    </div>
                </Grid>
            </Box>
        </ThemeProvider>
    )
}

export default AuthorizationDetail;