import axios from 'axios';
import { baseAuthURL, httpHeaders } from '../ApiHelper';

const authenticate = async (credentials) => {
    let payload = {
        login: credentials.username,
        password: credentials.password
    }

    let result = undefined;
    try {
        result = await axios.post(`${baseAuthURL}/login`, payload, { httpHeaders });
    }
    catch(error) {
        if(error.response !== undefined && error.response.status === 403) {
            return {error: 'Acesso Negado, verifique usuário e senha!', type: 'error'}
        }
        else {
            return {error: 'Acesso Negado, tente novamente mais tarde!', type: 'error'}
        }
    }

    return result;
}

export default authenticate;