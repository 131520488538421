import { Alert, Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

import "./UserMessage.css";

const UserMessage = ({message, type, loading = false}) => {
    let hiddenStyle  = '';
    if(message === undefined || message === null || message === '') {
        message = 'NONE';
        hiddenStyle  = 'userMessageHidden';
    }

    return (
        <div className='userMessageContainer'>
            <Alert severity={type} className={`userMessage ${hiddenStyle}`}> { message } </Alert>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default UserMessage;