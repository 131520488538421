import { baseAuthURL, doDELETE, doGET, doPOST, doPUT } from "../../ApiHelper"

const path = `${baseAuthURL}/company`;

export const findCompanies = async(query, onlyActive, itemsPerPage, offset, {setToken}) => {
    let params = [];
    params.push(`limit=${itemsPerPage}`);
    params.push(`offset=${offset}`);

    if(onlyActive === true) {
        params.push(`isActive=${onlyActive}`)
    }

    if(query !== '') {
        params.push(`query=${query}`)
    }

    let findPath = `${path}?${params.join("&")}`
    return await doGET(findPath, {setToken});
}

export const inactivateCompany = async(id, {setToken}) => {
    const url = `${path}/${id}`;
    return doDELETE(url, {setToken});
}

export const loadDetail = async(id, {setToken}) => {
    return doGET(`${path}/${id}`, {setToken});
}

export const save = (id, name, responsibleName, responsiblePhone, responsibleEmail, domains, isActive, {setToken}) => {
    let domainsArray = '';
    if(typeof domains === 'string') {
        domainsArray = domains.split(',');
    }

    let data = {
        "name": name,
        "responsibleName": responsibleName,
        "responsiblePhone": responsiblePhone,
        "responsibleEmail": responsibleEmail,
        "domains": domainsArray,
        "isActive": isActive
    }

    if(id === undefined) {
        return doPOST(path, data, {setToken});
    }
    else {
        return doPUT(`${path}/${id}`, data, {setToken});
    }
}