import { baseAuthURL, doDELETE, doGET, doPOST, doPUT } from "../../ApiHelper"

const path = `${baseAuthURL}/user`;
const pathRole = `${baseAuthURL}/role`;
const pathRoleUser = `${baseAuthURL}/role-user`;

export const findUsers = async(query, onlyActive, itemsPerPage, offset, {setToken}) => {
    let params = [];
    params.push(`limit=${itemsPerPage}`);
    params.push(`offset=${offset}`);

    if(onlyActive === true) {
        params.push(`isActive=${onlyActive}`)
    }

    if(query !== '') {
        params.push(`query=${query}`)
    }

    let findPath = `${path}?${params.join("&")}`
    return await doGET(findPath, {setToken});
}

export const findActiveRoles = ({setToken}) => {
    return doGET(`${pathRole}?isActive=true&withPagination=false`, {setToken});
}

export const findUserRoles = (id, {setToken}) => {
    return doGET(`${pathRoleUser}?user=${id}&withPagination=false`, {setToken});
}

export const inactivateUser = async(id, {setToken}) => {
    const url = `${path}/${id}`;
    return doDELETE(url, {setToken});
}

export const loadUserDetail = async(id, {setToken}) => {
    return doGET(`${path}/id/${id}`, {setToken});
}

export const save = (id, login, name, isActive, isRequiredChangePassword, {setToken}) => {
    let data = {
        "login": login,
        "name": name,
        "isActive": isActive,
        "isRequiredChangePassword": isRequiredChangePassword
    }

    if(id === undefined) {
        return doPOST(path, data, {setToken});
    }
    else {
        return doPUT(`${path}/${id}`, JSON.stringify(data), {setToken});
    }
}

export const saveUserRoles = (userId, roles, {setToken}) => {
    let data = {
        "roles": roles
    }

    return doPUT(`${pathRoleUser}/user/${userId}`, JSON.stringify(data), {setToken});
}