import React, {useState, useEffect} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserMessage from '../../Components/UserMessage/UserMessage';
import { useParams, useNavigate } from 'react-router-dom';
import useToken from '../../App/useToken';
import { Box } from '@mui/system';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, Link, Switch, TextField, Typography } from '@mui/material';
import { findActiveRoles, findUserRoles, loadUserDetail, save, saveUserRoles } from './UserApi';
import './User.css';

const UserDetail = () => {
    const theme = createTheme();
    const {token, setToken} = useToken();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [login, setLogin] = useState('');
    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isRequiredChangePassword, setIsRequiredChangePassword] = useState(false);
    const [detail, setDetail] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    let navigate = useNavigate();

    useEffect(() => {
        loadPage();
    });

    const loadPage = () => {
        if(pageLoaded === false) {
            setPageLoaded(true);
            if(id !== undefined) {
                setLoading(true);
                loadUserDetail(id, {setToken}).then((res) => {
                    setDetail(res.data);
                    setLogin(res.data.login);
                    setName(res.data.name);
                    setIsActive(res.data.isActive);
                    setIsRequiredChangePassword(res.data.isRequiredChangePassword);
                    setLoading(false);
                });
                loadUserRoles();
            }
        }
    }

    const loadUserRoles = () => {
        findUserRoles(id, {setToken}).then((res) => {
            if(res.error === undefined) {
                setUserRoles(res.data.list);
                let userSelectedRoles = [];
                res.data.list.map((selectedRole) => {
                    userSelectedRoles.push(selectedRole.role.id);
                });
                setSelectedRoles(userSelectedRoles);
            }
        });
    }

    const loadActiveRoles = () => {
        if(roleList.length === 0) {
            findActiveRoles({setToken}).then((res) => {
                if(res.error === undefined) {
                    setRoleList(res.data.list);
                }
            })
        }
    }

    const handleOpenRolesDialog = () => {
        setIsRolesDialogOpen(true);
        loadActiveRoles();
    }

    const handleRolesDialogCancel = () => {
        setIsRolesDialogOpen(false);
    }

    const handleRolesDialogSave = () => {
        saveUserRoles(id, selectedRoles, {setToken}).then((res) => {
            setMessage('Atualizando perfis do usuário...');
            setMessageType('warning');
            if(res.error === undefined) {
                setMessage('Perfis do usuário atualizados com sucesso!');
                setMessageType('success');
                loadUserRoles();
            }
            else {
                setMessage('Erro ao atualizar perfis do usuário! Tente novamente');
                setMessageType('error');
                setSelectedRoles([...userRoles]);
            }
            setIsRolesDialogOpen(false);
        });
    }

    const onChangeLogin = (event) => {
        setLogin(event.target.value);
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangeIsActive = (event) => {
        setIsActive(event.target.checked);
    }

    const onChangeIsRequiredChangePassword = (event) => {
        setIsRequiredChangePassword(event.target.checked);
    }

    const handleBackClick = () => {
        navigate(`/user`);
    }

    const handleSaveClick = () => {
        setMessage('Salvando...');
        setMessageType('warning');
        setLoading(true);

        save(id, login, name, isActive, isRequiredChangePassword, {setToken}).then((res) => {
            navigate(`/user`);
        })
    }

    const handleRoleClick = (event) => {
        if(event.target.checked === true) {
            setSelectedRoles([...selectedRoles, event.target.value]);
        }
        else {
            setSelectedRoles(selectedRoles.filter(role => role !== event.target.value));
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Box className='contentHolder' role='presentation'>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography component="h1" variant="h5" className='pageTitle'>
                        {id === undefined ? 'Novo Usuário' : 'Detalhes do Usuário'}
                    </Typography>
                    <UserMessage message={message} type={messageType} loading={loading} />
                    <TextField fullWidth className='formInput' onChange={onChangeLogin} value={login} label="Login" variant="standard" />
                    <TextField fullWidth className='formInput' onChange={onChangeName} value={name} label="Nome"  variant="standard" />
                    <FormControlLabel 
                        control={<Switch onClick={onChangeIsActive} checked={isActive} inputProps={{ 'aria-label': 'controlled' }} />}
                        label="Ativo?" />
                    <FormControlLabel
                        control={<Switch onClick={onChangeIsRequiredChangePassword} checked={isRequiredChangePassword}
                        inputProps={{ 'aria-label': 'controlled' }} />}
                        label="Trocar senha?" />
                    {id &&
                        <Link component='button' onClick={handleOpenRolesDialog}>
                            Perfis
                        </Link>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.company === undefined || detail.company.name === undefined ? '' : detail.company.name}
                                label="Empresa" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' value={detail.createdBy === undefined ? '' : detail.createdBy} label="Criado por" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' value={detail.lastUpdatedBy === undefined ? '' : detail.lastUpdatedBy} label="Atualizado por" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.createdAt === undefined ? '' : new Date(detail.createdAt).toLocaleString('pt-BR')}
                                label="Criado em" disabled variant="standard" />
                        </FormControl>
                    }
                    {id &&
                        <FormControl fullWidth variant="standard">
                            <TextField fullWidth className='formInput' 
                                value={detail.lastUpdatedAt === undefined ? '' : new Date(detail.lastUpdatedAt).toLocaleString('pt-BR')}
                                label="Atualizado em" disabled variant="standard" />
                        </FormControl>
                    }
                    <div className="buttonGroup">
                        <Button onClick={handleBackClick} variant='contained'>Voltar</Button>
                        <Button onClick={handleSaveClick} variant='contained' color='success'>Salvar</Button>
                    </div>
                </Grid>
            </Box>
            <Dialog open={isRolesDialogOpen} scroll='paper' className='rolesDialog'>
                <DialogTitle>Perfis</DialogTitle>
                <DialogContent dividers>
                    <FormGroup>
                        {roleList.length === 0 &&
                                "Carregando..."
                        }
                        {roleList.length > 0 &&
                            roleList.map((role) => (
                                <FormControlLabel key={`lbl${role.id}`}
                                    control={<Checkbox key={role.id} checked={selectedRoles.includes(role.id)} onClick={handleRoleClick} value={role.id} />}
                                    label={role.name} />
                            ))
                        }
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleRolesDialogCancel}>Cancelar</Button>
                    <Button onClick={handleRolesDialogSave}>Salvar</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default UserDetail;